/**
* Secondary Navigation For Squarespace 
* Copyright Will-Myers.com & Abi Bacon
* Development Lead, Abi Bacon
**/

[data-wm-plugin="secondary-nav"] {
  /* --padding-horizontal: 4vw;
  --padding-vertical: 10px; */

  --border: none;

  --title-color: var(--siteTitleColor);
  --title-size: calc(
    (var(--site-title-font-font-size-value) - 1) * 1.2vw + 1rem
  );
  --link-color: var(--navigationLinkColor);
  --link-hover-color: var(--navigationLinkColor);
  --overlay-link-color: var(--menuOverlayNavigationLinkColor);
  --link-size: calc(
    (var(--site-navigation-font-font-size-value) - 1) * 1.2vw + 1rem
  );

  /* --overlay-link-size: 8.5vmin; */
  --link-spacing: 0.8vw;
  --dropdown-border: none;
  --desktop-logo-height: 25px;
  --mobile-logo-height: 25px;
}

/*Background Color*/
body.tweak-transparent-header
  .header:not(.shrink):not(.transparent-header-theme--override)
  [data-wm-plugin="secondary-nav"] {
  transition: background 300ms ease-in-out 0s;
}
[data-wm-plugin="secondary-nav"]:not([data-section-theme="adaptive"]){
  background-color: var(--siteBackgroundColor);
}

[data-wm-plugin="secondary-nav"] {
  width: 100%;
  box-sizing: border-box;
  transition: background 140ms ease-in-out 140ms;
}
body:not(.header--menu-open) .header:has([data-header-style="gradient"].header-border, 
body:not(.header--menu-open) [data-wm-plugin="secondary-nav"]) .header-announcement-bar-wrapper {
  position: static;
}

[data-wm-plugin="secondary-nav"] {
  pointer-events: auto;
  padding-left: var(--padding-horizontal, 4vw);
  padding-right: var(--padding-horizontal, 4vw);
  padding-top: var(--padding-vertical, 10px);
  padding-bottom: var(--padding-vertical, 10px);
  border: var(--border);
  z-index: 3;
  position: relative;
}
@media (max-width: 800px) {
  [data-wm-plugin="secondary-nav"] {
    padding-left: var(--padding-horizontal, 6vw);
    padding-right: var(--padding-horizontal, 6vw);
    padding-top: var(--padding-vertical, 10px);
    padding-bottom: var(--padding-vertical, 10px);
  }
}


.header .header-announcement-bar-wrapper{
  z-index: 3;
}

[data-wm-plugin="secondary-nav"] + .header-announcement-bar-wrapper {
  z-index: 2;
}
.header-announcement-bar-wrapper + [data-wm-plugin="secondary-nav"] {
  z-index: 2;
}
[data-wm-plugin="secondary-nav"] + .header-announcement-bar-wrapper + [data-wm-plugin="secondary-nav"] {
  z-index: 1;
}

[data-wm-plugin="secondary-nav"] .secondary-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[data-wm-plugin="secondary-nav"] .secondary-title {
  font-size: var(--title-size);
  color: var(--title-color, var(--siteTitleColor));
}

[data-wm-plugin="secondary-nav"] .secondary-logo img {
  height: var(--desktop-logo-height);
}

[data-wm-plugin="secondary-nav"] .secondary-links {
  display: flex;
  flex-wrap: wrap;
}

[data-wm-plugin="secondary-nav"] .wm-subnav-link {
  position: relative;
}

[data-wm-plugin="secondary-nav"] .wm-subnav-link .secondary-link {
  color: var(--link-color);
  font-size: var(--link-size);
}

[data-wm-plugin="secondary-nav"] .secondary-link:hover {
  color: var(--link-hover-color);
}

[data-wm-plugin="secondary-nav"] .secondary-nav-folder-content {
  opacity: 0;
  position: absolute;
  min-width: 200px;
  border: var(--dropdown-border);
  visibility: hidden;
  z-index: 4;
  padding: 0.5em 0;
}

[data-wm-plugin="secondary-nav"] .secondary-nav-folder-content a {
  padding: 0 1em;
}

[data-wm-plugin="secondary-nav"]
  .wm-subnav-folder:hover
  .secondary-nav-folder-content {
  opacity: 1;
  visibility: visible;
}

/*Sticky Nav Section*/
body:not(.sqs-edit-mode-active) #siteWrapper .page-section.secondary-nav-section{
  position: sticky;
  min-height: 0px;
  top: var(--wM-headerBottom);
  z-index: 20;
}
body:not(.sqs-edit-mode-active) #siteWrapper .page-section.secondary-nav-section .content-wrapper{
  padding:0px
}
body:not(.sqs-edit-mode-active) .page-section.secondary-nav-section .content {
  display: none;
}
body.sqs-edit-mode-active .page-section.secondary-nav-section .content-wrapper > [data-wm-plugin="secondary-nav"]{
  display: none;
}

/** Layouts **f/

/** Nav Right **/

[data-wm-plugin="secondary-nav"] .header-layout-nav-right .secondary-links {
  margin-left: auto;
}

[data-wm-plugin="secondary-nav"]
  .header-layout-nav-right
  .wm-subnav-link:not(:first-of-type) {
  margin-left: var(--link-spacing);
}

[data-wm-plugin="secondary-nav"]
  .header-layout-nav-right
  .secondary-nav-folder-content {
  text-align: right;
  left: auto;
  right: -1em;
}

/** Logo Center **/

[data-wm-plugin="secondary-nav"]
  .header-layout-branding-center
  .wm-subnav-link:not(:last-of-type) {
  margin-right: var(--link-spacing);
}

[data-wm-plugin="secondary-nav"]
  .header-layout-branding-center
  .secondary-branding-wrapper {
  order: 2;
  flex-grow: 1;
  width: 33%;
  text-align: center;
}

[data-wm-plugin="secondary-nav"]
  .header-layout-branding-center
  .secondary-links {
  order: 1;
  flex-grow: 1;
  width: 33%;
}

[data-wm-plugin="secondary-nav"]
  .header-layout-branding-center
  .header-actions {
  order: 3;
  flex-grow: 1;
  width: 33%;
}

/** Nav Left **/

[data-wm-plugin="secondary-nav"] .header-layout-nav-left .secondary-links {
  margin-right: auto;
}

[data-wm-plugin="secondary-nav"] .header-layout-nav-left .secondary-title {
  margin-right: 2.5vw;
}

/** Nav Center **/

[data-wm-plugin="secondary-nav"]
  .header-layout-nav-center
  .secondary-branding-wrapper {
    flex: 1 1 33%;
}

[data-wm-plugin="secondary-nav"] .header-layout-nav-center .secondary-links {
  flex: 1 1 100%;
  justify-content: center;
}

[data-wm-plugin="secondary-nav"] .header-layout-nav-center .wm-subnav-link {
  margin-left: 0.8vw;
  margin-right: 0.8vw;
}

[data-wm-plugin="secondary-nav"] .header-layout-nav-center .header-actions {
  flex: 1 1 33%;
}

/** Nav Only **/
[data-wm-plugin="secondary-nav"] .header-layout-nav-only .secondary-links {
  width: 100%;
  justify-content: center;
}
[data-wm-plugin="secondary-nav"] .header-layout-nav-only .wm-subnav-link:not(:last-of-type) {
  margin-left: 0.8vw;
  margin-right: 0.8vw;
}


.header-display-mobile.secondary-mobile-nav .header-title-nav-wrapper {
  display: block !important;
}

.header-display-mobile.secondary-mobile-nav .secondary-logo img {
  height: var(--mobile-logo-height);
}

/*Mobile Nav Spacing*/
.container + .secondary-header-menu-nav-wrapper{
  margin-top: 51px;
}
.secondary-header-menu-nav-wrapper + .container{
  margin-top: 51px;
}


body.header--menu-open .header-announcement-bar-wrapper + .secondary-nav.static {
  visibility: hidden;
  opacity: 0;
}
@media only screen and (max-width: 800px) {
  .secondary-nav:not(.static) {
    display: none;
  }

  .static .header-actions,
  .static .header-actions > * {
    display: flex !important;
  }

  .secondary-header-menu-nav-wrapper a {
    font-size: var(--overlay-link-size, 6.5vmin);
    color: var(--overlay-link-color, var(--menuOverlayNavigationLinkColor)) !important;
  }
}

@media (min-width: 576px) {
  .secondary-header-menu-nav-wrapper a {
    font-size: var(--overlay-link-size, 4.6vmin);
  }
}

@media (min-width: 768px) {
  .secondary-header-menu-nav-wrapper a {
    font-size: var(--overlay-link-size, 4vmin);
  }
}

/* Sticky Nav */

.secondary-nav.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
}

.secondary-nav + .sticky-placeholder {
  display: none;
}

SecondaryNav {
  display: none;
}
